import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@thebell/common/services/api/auth';
import { ClientAuthService } from "../../../../services/api/client-auth/src";
import { tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tokenPrefix: string;
  constructor(
    private auth: AuthService,
    private clientAuth: ClientAuthService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('skip')) {
      const headers = req.headers.delete('skip');
      return next.handle(req.clone({headers}));
    }

    const authReq = RegExp('graphql').test(req.url)
      ? this.addAuth(req, 'nest')
      : this.addAuth(req, 'laravel');

    return next.handle(authReq).pipe(
      tap({
        next: async (event) => {
          if (event instanceof HttpResponse
            && Array.isArray(event.body?.errors)
            && event.body.errors.length > 0
            && event.body.errors[0]?.extensions?.statusCode === 401
          ) {
            ClientAuthService.logout();

            if (isPlatformBrowser(this.platformId)) {
              await this.router.navigateByUrl('/account/login', { replaceUrl: true });
            }
          }
        }
      })
    );
  }

  private addAuth(req: HttpRequest<any>, target: 'nest' | 'laravel') {
    let accessToken;
    if (target === 'nest') {
      accessToken = this.auth.nestToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
    } else {
      accessToken = this.auth.laravelToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
    }

    if (accessToken === null) {
      this.tokenPrefix = 'client_auth_';
      if (target === 'nest') {
        accessToken = this.clientAuth.nestToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
      } else {
        accessToken = this.clientAuth.laravelToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
      }
    }

    //console.log(this.tokenPrefix)
    //console.log(accessToken)

    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
    });
  }
}
